import React, { useState } from 'react';
import PropTypes from 'prop-types';

import browserClient from '../services/browser-client';
import { makeStyles } from '@material-ui/core/styles';

const ConfigLogo = (props) => {
  const { defaultLogo, configKey, ...rest } = props;
  const [imageSrc, setImageSrc] = useState(defaultLogo);

  let isSubscribed = true;
  React.useEffect(() => {
    loadImageFromConfig();
    return () => (isSubscribed = false);
  }, []);

  const loadImageFromConfig = async () => {
    const newImage = await browserClient.getConfig(configKey);
    if (newImage) {
      setImageSrc(newImage);
    }
  };

  const useStyles = makeStyles((theme) => ({
    mainLogo: {
      width: '250px',
      'margin-bottom': '10px',
    },
  }));
  const classes = useStyles();
  return <img className={classes.mainLogo} alt="ID Control" src={imageSrc} {...rest} />;
};

ConfigLogo.propTypes = {
  defaultLogo: PropTypes.string.isRequired,
  configKey: PropTypes.string.isRequired,
};

export default ConfigLogo;